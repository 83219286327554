<template>
  <div class="recommend">
    <h1>第{{ inpo }}期职位推荐</h1>
    <ul class="job_list">
      <li class="box" v-for="(item, index) in list" :key="index">
        <div class="top flex">
          <div class="left">
            <div class="flex">
              <h3 class="line_clamp1">
                <router-link
                  :to="'/job/jobDetail/?id=' + item.id"
                  target="_blank"
                  :title="item.jobName"
                  >{{ item.jobName }}</router-link
                >
              </h3>
              <small
                >「{{ item.updateTime | currentYear(item.updateTime) }}」</small
              >
            </div>

            <p>
              <span class="money" v-if="item.jobSalary">{{
                item.jobSalary
              }}</span>
              <span class="money" v-else>面议</span>
              <span v-if="item.cityid">
                {{ item.cityid }}
                <em v-if="item.threeCityid">-</em>
                {{ item.threeCityid }}
                <i></i>
              </span>
              <span v-else>
                城市不限
                <i></i>
              </span>
              <span v-if="item.edu">
                {{ item.edu }}
                <i></i>
              </span>
              <span v-else>
                学历不限
                <i></i>
              </span>
              <span v-if="item.exp">
                {{ item.exp }}
                <i></i>
              </span>
              <span v-else>
                {{ item.exp }}
                <i></i>
              </span>
              <span v-for="(k, s) in item.jobDistypeOutDTOList" :key="s">
                {{ k.disType }}
                <em v-if="k.disTypeLevel">/</em>
                {{ k.disTypeLevel }}
              </span>
            </p>
          </div>
          <div class="right" v-if="item.companyInfo">
            <router-link
              :to="'/job/businessDetails/?companyId=' + item.companyId"
              target="_blank"
            >
              <h3 class="line_clamp1">{{ item.companyInfo.companyName }}</h3>
            </router-link>
            <div class="info">
              <p>
                <span v-if="item.companyInfo.hyTop">
                  {{ item.companyInfo.hyTop }}
                  <i></i>
                </span>
                <span v-else>
                  暂无行业类别
                  <i></i>
                </span>
                <span v-if="item.companyInfo.pr">
                  {{ item.companyInfo.pr }}
                  <i></i>
                </span>
                <span v-else>
                  暂无公司性质
                  <i></i>
                </span>
                <span v-if="item.companyInfo.mun">
                  {{ item.companyInfo.mun }}
                  <i></i>
                </span>
                <span v-else>
                  暂无企业人数
                  <i></i>
                </span>
              </p>
            </div>
          </div>
          <img :src="item.companyInfo.logo" class="logo img err_logo flexs" />
        </div>
        <div class="bottom flex">
          <div class="left" v-if="item.jobLabels.length > 0">
            <span v-for="(n, i) in item.jobLabels" :key="i">{{
              n.labelName
            }}</span>
          </div>
          <div class="left" v-else>
            <span>暂无福利标签</span>
          </div>
        </div>
      </li>
    </ul>
    <status :type="type" v-if="isHide"></status>
    <div class="pages">
      <el-pagination
        background
        class="work_pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="page.pageNum"
        :page-size="page.pageSize"
        layout="prev, pager, next, total, jumper"
        :total="page.total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import status from "@/components/public/status";
export default {
  name: "recommendJob",
  components: {
    status,
  },
  data() {
    return {
      list: [],
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 10,
      },
      type: "",
      isHide: false,
      loading: false,
      inpo: "",
    };
  },
  filters: {
    // 获取当前年
    currentYear(time) {
      let date = new Date();
      let years = date.getFullYear();
      let createTime = "";
      if (time.slice(0, 4) == years) {
        createTime = time.split(years + "年").join("");
      } else {
        createTime = time;
      }
      return createTime;
    },
  },
  methods: {
    async info() {
      let that = this;
      this.loading = true;
      const { inpo: queryInpo, id: queryId } = this.$route.query;
      let res = await this.$axios.post("/api/app-jycy-job/getJobTuiweklis", {
        ...this.page,
        inpo: queryInpo,
        id: queryId,
      });
      if (res.data.success) {
        this.loading = false;
        const { jobOutDTOIPage, inpo } = res.data.data;
        this.list = jobOutDTOIPage.records;
        this.inpo = inpo;
        this.page.total = jobOutDTOIPage.total;
        if (this.page.total <= 0) {
          that.isHide = true;
          that.type = "result";
        } else {
          that.isHide = false;
        }
      } else {
        that.isHide = true;
        that.type = "wifi";
        that.loading = false;
      }
    },
    //分页
    handleCurrentChange: function (val) {
      this.page.pageNum = val;
      this.info();
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
  },
  created() {
    this.info();
  },
};
</script>

<style scoped lang="less">
// @import "~assets/css/job.less";
@import "~assets/css/public.less";
h1 {
  position: relative;
  width: 1200px;
  margin: 20px auto 0 auto;
  padding: 20px 30px;
  background: white;
  font-size: 20px;
  border-bottom: 1px solid #e1e1e1;
}
h1::after {
  content: "";
  position: absolute;
  top: 15px;
  left: 10px;
  width: 6px;
  height: 30px;
  background: #00924c;
}
.job_list {
  width: 1200px;
  margin: 0 auto;

  .box {
    width: 100%;
    height: 130px;
    margin-bottom: 10px;
    box-shadow: 0px 1px 2px 0px rgba(35, 24, 21, 0.08);

    .top {
      height: 90px;
      padding: 18px 30px;
      background: white;

      .left {
        flex: 6;

        h3 {
          max-width: 280px;
          font-size: 18px;
          font-weight: 400;
          margin-bottom: 10px;
          color: @00;
        }

        small {
          font-size: 14px;
          color: @gray;
          padding-left: 10px;
        }

        p {
          margin-bottom: 10px;

          span {
            position: relative;
            font-size: 14px;
            color: @66;
            padding-right: 12px;

            i {
              .line(5px, 14px, @gray);
            }
          }

          span:last-child {
            i {
              display: none;
            }
          }

          .money {
            color: @ff5;
            font-size: 18px;
            font-weight: bold;
            padding-right: 10px;
          }
        }
      }

      .right {
        display: flex;
        flex-direction: column;
        justify-content: right;
        align-items: center;
        height: 100%;

        h3 {
          font-size: 14px;
          font-weight: 400;
          margin-bottom: 10px;
          width: 200px;
        }

        a {
          text-align: right;
        }

        .info {
          p {
            margin-bottom: 10px;

            span {
              position: relative;
              font-size: 12px;
              color: @66;
              padding-right: 12px;

              i {
                .line(6px, 14px, @gray);
              }
            }

            span:last-child {
              i {
                display: none;
              }
            }
          }
        }
      }

      .logo {
        width: 56px;
        height: 56px;
        margin-left: 15px;
      }
    }

    .bottom {
      height: 40px;
      background: #fafafa;
      padding: 0 30px;

      .left,
      .right {
        padding-top: 9px;
      }

      .left {
        flex: 6;

        span {
          display: inline-block;
          padding: 0 10px;
          font-size: 12px;
          border: 1px solid @e1;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          border-radius: 5px;
          background: white;
          margin-right: 10px;
        }
      }

      .right {
        display: flex;

        span {
          font-size: 12px;
          color: @66;
        }

        span:last-child {
          color: @00;
        }

        .schedule {
          width: 130px;
          height: 6px;
          background: #cccccc;
          margin: 7px 20px;

          div {
            width: 0%;
            height: 100%;
            background: @00;
          }
        }
      }
    }
  }
}
</style>
