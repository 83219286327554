import { render, staticRenderFns } from "./recommendJob.vue?vue&type=template&id=b41cf366&scoped=true"
import script from "./recommendJob.vue?vue&type=script&lang=js"
export * from "./recommendJob.vue?vue&type=script&lang=js"
import style0 from "./recommendJob.vue?vue&type=style&index=0&id=b41cf366&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b41cf366",
  null
  
)

export default component.exports